'use client';
import ThemeLink from '@/components/link';
import React, { Fragment, useEffect } from 'react';
import {
  getCenterLink,
  getLoginLink,
  getRegisterLink,
} from '@/lib/util/get-router';
import { useUserStore } from '@/lib/store/user.store';
import { useAuth } from '@/hooks/useAuth';
import { useUserInfo } from '@/hooks/useUserInfo';
import { usePathname } from 'next/navigation';

const welcomeMessage = '您好！欢迎来到恒配达商城';

function PromoLeft() {
  const { userInfo } = useUserStore();
  const { getUserInfo } = useUserInfo();
  const [username, setUserName] = React.useState<string>('');
  const { logout } = useAuth();
  const pathname = usePathname();
  useEffect(() => {
    setUserName(userInfo?.member?.nickname || '');
  }, [userInfo]);
  useEffect(() => {
    getUserInfo();
  }, [pathname]);
  return (
    <div className="s-flex gap-x-4">
      <ThemeLink href="/">{welcomeMessage}</ThemeLink>
      <p className="s-flex gap-x-1">
        {username ? (
          <Fragment>
            <ThemeLink href={getCenterLink()}>{username}</ThemeLink>
            <span>|</span>
            <span
              className="cursor-pointer hover:text-main duration-300"
              onClick={logout}
            >
              退出登录
            </span>
          </Fragment>
        ) : (
          <Fragment>
            <ThemeLink href={getLoginLink()}>登录</ThemeLink>
            <span>|</span>
            <ThemeLink href={getRegisterLink()}>注册</ThemeLink>
          </Fragment>
        )}
      </p>
    </div>
  );
}

export default React.memo(PromoLeft);
