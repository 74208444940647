'use client';
import {
  get3DModelLink,
  getBrandLink,
  getCatalogLink,
  getDesignExampleLink,
  getDesignSoftware,
  getDynamicIndustryNewsLink,
  getDynamicKnowledgeLink,
  getEbookLink,
  getForumList,
  getProductCenterLink,
} from '@/lib/util/get-router';
import ThemeLink from '@/components/link';
import React, { useEffect, useState } from 'react';
import { usePathname } from 'next/navigation';

const menu = [
  {
    id: 1,
    title: '商城首页',
    link: '/',
  },
  {
    id: 2,
    title: '产品中心',
    link: getProductCenterLink(),
  },
  {
    id: 3,
    title: '品牌中心',
    link: getBrandLink(),
  },
  {
    id: 4,
    title: '目录资料',
    link: getCatalogLink(),
  },
  {
    id: 5,
    title: '设计案例',
    link: getDesignExampleLink(),
  },
  {
    id: 9,
    title: '设计软件',
    link: getDesignSoftware(),
    clid: [
      // {
      //   id: 6,
      //   title: '资源开放',
      //   link: '',
      // },
      {
        id: 7,
        title: '恒配达论坛',
        link: getForumList(),
      },
      {
        id: 8,
        title: '产品知识',
        link:getDynamicKnowledgeLink(),
      },
    ],
  },
  {
    id: 10,
    title: '电子书',
    link: getEbookLink(),
  },
];
export default function Menu() {
  const pathname = usePathname();
  const [activeRouter, setActiveRouter] = useState(pathname);
  const [hoveredItem, setHoveredItem] = useState<number | null>(null);

  useEffect(() => {
    setActiveRouter(pathname);
  }, [pathname]);

  return (
    <>
      {menu.map((item) => {
        return (
          <li 
            key={item.id}
            className="relative"
            onMouseEnter={() => setHoveredItem(item.id)}
            onMouseLeave={() => setHoveredItem(null)}
          >
            <ThemeLink
              href={item.link}
              className={`${
                item.link === activeRouter
                  ? '!text-main'
                  : '!text-black hover:!text-main'
              }`}
            >
              {item.title}
            </ThemeLink>
            
            {/* 子菜单渲染 */}
            {item.clid && hoveredItem === item.id && (
              <ul className="absolute left-0 top-full min-w-[200px] bg-white   py-2 z-50">
                {item.clid.map((subItem) => (
                  <li key={subItem.id} className="px-4 py-2 ">
                    <ThemeLink
                      href={subItem.link}
                      className="!text-[#000]  text-[14px] hover:!text-main "
                    >
                      {subItem.title}
                    </ThemeLink>
                  </li>
                ))}
              </ul>
            )}
          </li>
        );
      })}
    </>
  );
}
