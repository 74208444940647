"use client";
import {Button} from "antd";
import MyCart from "@/components/header/nav-bar/my-cart";
import React from "react";
import {getCartCount} from "@/service/action/cart-list";
import { useInquiryStore } from "@/lib/store/inquiry.store";


type Props = {
    count: number
}


export default  function NavBarRight({
    count
}: Props) {
    const { inquiryList, setInquiryList, isquickQuote, setIsquickQuote } = useInquiryStore();
    return <div className="b-flex gap-x-6">
        <Button type="primary" size={"large"} className="!rounded-[0]" href="/center/inquiry"
        onClick={() => {
            return setIsquickQuote(true);
        }
        }
            >快速报价</Button>
        <MyCart count={count}/>
    </div>;
}


