import {
  requestGetCartCount,
  requestAddToCart,
  requestDeleteCartItem,
} from '@/lib/api/cart';
import { useCartStore } from '@/lib/store/cart.store';
import { App } from 'antd';
type addType = {
  sku_id: string;
  number: number | string;
};
export const useShopCart = () => {
  const { setCartCount, getCartList, getCartCount, setCartList } =
    useCartStore();
  const { message } = App.useApp();
  const addToCart = async ({ sku_id, number }: addType) => {
      try {
        const res = await requestAddToCart({ sku_id, number });
        if (res.code !== 200) {
          message.error(res.message);
        } else {
          message.open({
            type: 'success',
            content: '加入购物车成功',
            duration: 2,
            key: 'addToCart',
          });
          await getCartList();
          await getCartCount();
        }
      } catch (error) {
        console.log(error);
        message.error('添加购物车失败');
      }
      return true;
    },
    deleteShopCart = async (ids: number[]) => {
      try {
        message.open({
          type: 'loading',
          content: 'Loading...',
        });
        const res = await requestDeleteCartItem(ids);
        if (res.code !== 200) {
          message.error(res.message);
          return false;
        }
        message.open({
          type: 'success',
          content: '删除成功',
          duration: 2,
          key: 'deleteShopCart',
        });
        await getCartCount();
        await getCartList();
        return true;
      } catch (err) {
        console.log(err);
        message.error('删除购物车商品失败');
        return false;
      }
    };

  return {
    addToCart,
    deleteShopCart,
  };
};
