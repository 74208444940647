import {create} from "zustand";
import {ProductSkuNumType} from "@/lib/type/product.type";
import {SuccessOrderResponseType} from "@/lib/type/myOrder";
import {persist} from "zustand/middleware";

type CheckStore = {
    checkoutData: ProductSkuNumType[];
    setCheckoutData: (list: ProductSkuNumType[]) => void;
    buyNowTotal: number;
    setBuyNowTotal: () => void;
    // 1：立即下单 2：购物车下单： 3：创建多个商品订单
    checkoutType:number
    setCheckoutType:(type:number)=>void
    //订单id
    orderId:string,
    setOrderId:(id:string)=>void,
    // orderList:{order_id:string}[],
    // setOrderList:(data:{order_id:string}[])=>void
}


export const useCheckoutStore = create(
    persist<CheckStore>(
        (set, get) => ({
            checkoutData: [],
            setCheckoutData(list: ProductSkuNumType[]) {
                set({checkoutData: list});
                get().setBuyNowTotal();
            },
            buyNowTotal: 0,
            setBuyNowTotal() {
                const list = get().checkoutData;
                const onSale =list?.length>0 ? list.filter(item => item.status === "1") :[];
                const total =onSale?.length>0 ? onSale.reduce((total, item) => {
                    return total + (item.count * (+item.price));
                }, 0) : 0;
                set({
                    buyNowTotal: total
                });
            },
            checkoutType:0,
            setCheckoutType(type:number){
                set({checkoutType:type})
            },
            orderId:'',
            setOrderId(id:string){
                set({orderId:id})
            },
            // orderList:[],
            // setOrderList:(data:{order_id:string}[])=>{
            //     set({orderList:data})
            // }
        }),
        {
            name: "__checkoutStore",
            // 需要持久化存储的key
            partialize: (state: CheckStore): any => ({
                checkoutData: state.checkoutData,
                buyNowTotal:state.buyNowTotal,
                checkoutType:state.checkoutType,
                orderId:state.orderId
            })
        }
    )
);