import { create } from "zustand";
import { persist } from "zustand/middleware";
import { GoodsCategoriesItemInterface } from "@/lib/type/goods-categories.type";
import { mountStoreDevtool } from 'simple-zustand-devtools'

type InquiryStor = {
    inquiryList: any[];
    isquickQuote: boolean | null;
    setInquiryList: (newList: any[]) => void;
    setIsquickQuote: (bol: boolean) => void;
}


export const useInquiryStore = create(
    persist<InquiryStor>(
        (set, get) => ({
            inquiryList: [],
            isquickQuote: null,
            setInquiryList: (newList: any) => set({ inquiryList: newList }),
            setIsquickQuote: (bol: boolean) => set({ isquickQuote: bol })
        }),
        {
            name: "_isquickQuote",
            // 需要持久化存储的key
            partialize: (state: InquiryStor): any => ({
                isquickQuote: state.isquickQuote
            })
        }
    )
    
);

if (process.env.NODE_ENV === 'development') {
    mountStoreDevtool('useInquiryStore', useInquiryStore)
}
