import Link, {LinkProps} from "next/link";
import clsx from "clsx";
import React from "react";

type Props = {
    children: React.ReactNode;
    className?: string;
    style?: React.CSSProperties
} & LinkProps & React.AnchorHTMLAttributes<HTMLAnchorElement>
export default function ThemeLink(props: Props) {
    return <Link {...props} className={clsx("text-themeP hover:text-main duration-300", props?.className || "")}
                 style={props?.style || {}}>
        {props.children}
    </Link>;
}
