import request from "../util/request";
import {
    AccessToken,
    AccountLoginParams,
    AccountRegisterParams,
    GetSmsCodeParams,
    MobileLoginParams,
    UserInfoInterface
} from "@/lib/type/login-register.type";

// 验证码登录
export const requestMobileCodeLogin = (data: MobileLoginParams) => request<UserInfoInterface>({
    endpoint: "/api/tiny-shop/v1/site/mobile-login",
    method: "POST",
    body: data,
    cache: "no-cache"
});

// 获取手机验证码
export const requestGetSmsCode = (data: GetSmsCodeParams) => request({
    endpoint: "/api/tiny-shop/v1/site/sms-code",
    method: "POST",
    body: data,
    cache: "no-cache"
});

// 账号密码登录
export const requestAccountLogin = (data: AccountLoginParams) => request<UserInfoInterface>({
    endpoint: "/api/tiny-shop/v1/site/login",
    method: "POST",
    body: data,
    cache: "no-cache"
});


// 账号密码注册
export const requestAccountRegister = (data: AccountRegisterParams) => request<UserInfoInterface>({
    endpoint: "/api/tiny-shop/v1/site/register",
    method: "POST",
    body: data,
    cache: "no-cache"
});

// 退出登录
export const requestLogout = (params?: AccessToken) => request({
    endpoint: "/api/tiny-shop/v1/site/logout",
    method: "POST",
    params,
    cache: "no-cache"
});
