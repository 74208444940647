import React from "react";
import clsx from "clsx";

type Props = {
    price: number | string;
} & React.HTMLProps<HTMLSpanElement>
export default function Price(props: Props) {
    const price = +props.price;
    const priceSlice = price.toString().split(".");
    const priceAtLength = priceSlice.at(-1)?.length || 0;
    let result: string = price.toString();
    if (priceAtLength > 2) {
        result = priceSlice[0] + "." + priceSlice.at(-1)?.slice(0, 2);
    }
    return <span className={clsx(props?.className || "", "")}>
        ￥{result}
    </span>;
}
