import {requestLogout} from "@/lib/api/login-register";
import {useUserStore} from "@/lib/store/user.store";
import {App} from "antd";

export const useAuth = () => {
    const {removeUserInfo} = useUserStore();
    const {message} = App.useApp();
    const logout = async () => {
        try {
            message.open({
                type: "loading",
                content: "正在登出...",
                duration: 0,
                key: "logout"
            });
            await requestLogout();
        } finally {
            message.open({
                type: "success",
                content: "退出成功",
                duration: 2,
                key: "logout"
            });
            removeUserInfo();
            window.location.href = "/";
        }

    };

    return {
        logout
    };
};
