"use client";
import { CartsItemsInterface, CartsProductInterface } from "@/lib/type/cart.type";
import { Button, Drawer, Empty, InputNumber, Spin } from "antd";
import { useCartStore } from "@/lib/store/cart.store";
import { useEffect, useState } from "react";
import ThemeLink from "@/components/link";
import {getCheckoutLink, getProductDetailLink} from "@/lib/util/get-router";
import Price from "@/components/product/price";
import  {useShopCart} from '@/hooks/useShopCart'
import {useCheckoutStore} from "@/lib/store/checkout";
import {useRouter} from "next/navigation";
import {sleep} from "@/lib/util/util";

type Props = {
    open: boolean;
    onClose: () => void;
}
export default function CartDrawer({
    open,
    onClose
}: Props) {
    const [loading, setLoading] = useState(false);
    const { cartList, deleteCartList, updateCartItemNumber, cartTotal } = useCartStore();
    const { setCheckoutType } = useCheckoutStore()
    const router = useRouter()
    const {deleteShopCart} =useShopCart()
    const [products, setProducts] = useState<CartsItemsInterface[]>(useCartStore.getState().cartList || []);

    const handleUpdateNumber = (product: CartsProductInterface, count: number | null) => {
        if (product.status !== "1") return;
        count !== null && updateCartItemNumber(product.id, count);
    };

    const onfinish = async () => {
        setCheckoutType(2)
        await sleep(500)
        onClose()
        router.push(getCheckoutLink())
    }

    useEffect(() => {
        setProducts(cartList);
    }, [cartList]);
    return <Drawer open={open} onClose={onClose} title="购物车" width={500}>
        <Spin spinning={loading} size="large">
            {
                products?.length ? <>
                    <div className="mt-1">
                        <div className="flow-root">
                            <ul role="list" className="-my-6 divide-y divide-gray-200">
                                {products?.map((product) => (
                                    <li key={product.product.id} className="flex py-6">
                                        <div
                                            className="h-24 w-24 flex-shrink-0 overflow-hidden rounded-md border border-gray-200">
                                            <img
                                                src={product.product.product_picture}
                                                alt={product.product.product_name}
                                                className="h-full w-full object-cover object-center"
                                            />
                                        </div>

                                        <div className="ml-4 flex flex-1 flex-col">
                                            <div>
                                                <div
                                                    className="flex justify-between text-base font-medium text-gray-900">
                                                    <h3>
                                                        <ThemeLink
                                                            onClick={(e) => {
                                                                if (product.product.status !== "1") e.preventDefault();
                                                            }}
                                                            className="group"
                                                            href={getProductDetailLink(product.product.product_slug)}>
                                                            <span
                                                                className="text-black group-hover:text-main duration-300">{product.product.product_name}</span>
                                                        </ThemeLink>
                                                    </h3>
                                                    <p className="ml-4"><Price price={product.product.price} /></p>
                                                </div>
                                                <p className="mt-1 text-sm text-gray-500">{product.product.sku_name.join(";")}</p>
                                            </div>
                                            <div className="flex flex-1 items-end justify-between text-sm">
                                                <div className="text-gray-500">数量：
                                                    <InputNumber
                                                        disabled={product.product.status !== "1"}
                                                        min={product.product.min_buy === 0 ? 1 : product.product.min_buy}
                                                        max={product.product.max_buy === 0 ? +product.product.stock : product.product.max_buy}
                                                        defaultValue={+product.product.number}
                                                        value={+product.product.number}
                                                        onChange={(count: number | null) => handleUpdateNumber(product.product, count)}
                                                        changeOnWheel={true} size="small" />
                                                </div>
                                                <div className="c-flex flex-col">
                                                    <Button
                                                        size={"small"}
                                                        type="link"
                                                        danger
                                                        onClick={() => deleteShopCart([+product.product.id])}
                                                    >
                                                        删除
                                                    </Button>
                                                    <p className="text-xs text-gray-500">{product.product.remark}</p>
                                                </div>
                                            </div>
                                        </div>
                                    </li>
                                ))}
                            </ul>
                        </div>
                    </div>
                    <div className="mt-6 border-t border-gray-200 py-6">
                        <div className="flex justify-between text-base font-medium text-gray-900">
                            <p>总价</p>
                            <p>
                                <Price price={cartTotal} />
                            </p>
                        </div>
                        <p className="mt-0.5 text-sm text-gray-500">结账时计算运费</p>
                        <div className="mt-6">
                            <Button type="primary" block size="large" onClick={onfinish}>结账</Button>
                        </div>
                    </div>
                </> : <Empty image={Empty.PRESENTED_IMAGE_SIMPLE} description="购物车空空如也" />
            }
        </Spin>
    </Drawer>;
}
