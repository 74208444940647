'use client';

import clsx from 'clsx';
import { MenuOutlined } from '@ant-design/icons';
import { Placeholder } from '@/components/placeholder';
import React, { useEffect, useState } from 'react';
import { GoodsCategoriesItemInterface } from '@/lib/type/goods-categories.type';
import { usePathname } from 'next/navigation';
import ThemeLink from '@/components/link';
import { useGoodsStore } from '@/lib/store/goods.store';
import { Tooltip } from 'antd';
import {
  getProductCenterCategoryLink,
  getProductCenterSlugLink,
} from '@/lib/util/get-router';

type Props = {
  goodsCategories: GoodsCategoriesItemInterface[];
};

export default function Page({ goodsCategories }: Props) {
  const pathname = usePathname();
  const { setGoodsCategories } = useGoodsStore();
  const [show, setShow] = useState(pathname === '/');
  const [showId, setShowId] = useState('');
  const [currentShowCategory, setCurrentShowCategory] = useState<
    GoodsCategoriesItemInterface[]
  >([]);
  const findChild = (id: string) => {
    return goodsCategories.find((item) => item.id === id)?.child || [];
  };
  const findTitle = (id: string) => {
    return goodsCategories.find((item) => item.id === id)?.title || '';
  };
  useEffect(() => {
    if (showId) {
      setCurrentShowCategory(findChild(showId));
    }
  }, [showId]);
  useEffect(() => {
    setShow(pathname === '/');
  }, [pathname]);
  useEffect(() => {
    if (goodsCategories.length > 0) {
      setGoodsCategories(goodsCategories);
    }
  }, [goodsCategories]);

  return (
    <div className="relative w-fit">
      <div
        onClick={() => setShow(!show)}
        className={clsx(
          's-flex gap-x-4 z-[1] relative text-white py-2 w-full px-12 bg-main rounded-t-[10px] cursor-pointer duration-300',
          show ? '' : 'overflow-hidden'
        )}
      >
        <MenuOutlined />
        <span>产品分类</span>
        <div className="absolute top-[70%] left-[25px]">
          <Placeholder
            src="/site/home-line.png"
            imageWidth={17}
            imageHeight={40}
          />
        </div>
        <div className="absolute top-[70%] right-[25px]">
          <Placeholder
            src="/site/home-line.png"
            imageWidth={17}
            imageHeight={40}
          />
        </div>
      </div>
      <div
        className={clsx(
          'absolute h-[573px] z-[9] w-full',
          !show ? ' hidden' : ''
        )}
        onMouseLeave={() => setShowId('')}
      >
        <ul
          id="head-menu-cate"
          className={
            'relative py-3 flex flex-col justify-around bg-white rounded-[10px] mt-2 border shadow text-themeP text-sm font-normal w-full h-[573px] overflow-y-auto'
          }
        >
          {goodsCategories.map((item, index) => {
            return (
              <li
                key={item.id}
                onMouseEnter={() => setShowId(item.id)}
                className={clsx(
                  'py-3 px-3 b-flex cursor-pointer duration-300 last:rounded-b-[10px] first:rounded-t-[10px]',
                  showId === item.id ? 'bg-main text-white' : ''
                )}
              >
                <ThemeLink href={getProductCenterCategoryLink(item.id)}>
                  <div className="s-flex ">
                    <i
                      className={clsx(
                        item.class,
                        'text-main ri-lg pr-2 duration-300',
                        showId === item.id ? 'text-white' : ''
                      )}
                    ></i>
                    <span
                      className={`line-clamp-1 text-black duration-300 ${
                        showId === item.id && 'text-white'
                      }`}
                    >
                      {item.title}
                    </span>
                  </div>
                </ThemeLink>
                <i className="ri-arrow-right-s-line"></i>
              </li>
            );
          })}
        </ul>
        <div
          className={clsx(
            'absolute left-full block mt-2 z-[98] top-0 h-full overflow-hidden',
            showId ? 'block' : 'hidden'
          )}
        >
          <div className="border ml-4 py-3 px-5  w-[1000px] h-full  bg-white  rounded-[10px] shadow !text-black overflow-y-auto">
            <ul className="grid grid-cols-6 text-sm font-normal">
              {currentShowCategory.map((item) => {
                return (
                  <li key={item.id} className="py-3 px-3">
                    <ThemeLink
                      href={getProductCenterSlugLink(item.pid, item.id)}
                      className="!text-black group hover:!text-main c-flex w-full h-full flex-col"
                    >
                      {item.cover ? (
                        <Placeholder
                          src={item.cover}
                          imageWidth={120}
                          imageHeight={120}
                          fit="cover"
                        />
                      ) : (
                        <i className="ri-image-circle-line block w-[120px] group-hover:text-main duration-300 text-themeP h-[120px] c-flex ri-4x"></i>
                      )}
                      <span className="line-clamp-1">{item.title}</span>
                    </ThemeLink>
                  </li>
                );
              })}
            </ul>
          </div>
        </div>
      </div>
    </div>
  );
}
