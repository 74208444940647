import {requestMemberInfo, requestUpdateMemberInfo} from "@/lib/api/member";
import {useUserStore} from "@/lib/store/user.store";
import {MemberInfoInterface} from "@/lib/type/login-register.type";
import {UpdateMemberParams} from "@/lib/type/member.type";
import {App} from "antd";

type UpdateMember = {
    setLoading?: (loading: boolean) => void;
    member: UpdateMemberParams;
}

export const useUserInfo = () => {
    const {message} = App.useApp();
    const {removeUserInfo, setUserInfo, setMemberInfo} = useUserStore();
    const getUserInfo = async (): Promise<MemberInfoInterface | null> => {
        const token = useUserStore?.getState()?.userInfo?.access_token || "";
        if (token) {
            const res = await requestMemberInfo({"access-token": token});
            if (res.code === 200) {
                setMemberInfo(res.data.member);
                return res.data.member;
            } else {
                removeUserInfo();
                return null;
            }
        }
        return null;
    };

    // 更新会员信息
    const uploadMemberInfo = async ({
                                        setLoading,
                                        member
                                    }: UpdateMember) => {
        try {
            setLoading?.(true);
            const res = await requestUpdateMemberInfo({id: useUserStore.getState()?.userInfo?.member?.id || ""}, member);
            if (res.code !== 200) {
                message.error(res.message);
            } else {
                const memberInfo = await requestMemberInfo();
                if (memberInfo.code === 200) {
                    setMemberInfo(memberInfo.data.member);
                    message.success("修改成功");
                } else {
                    message.error(memberInfo.message);
                }
            }
        } finally {
            setLoading?.(false);
        }
    };

    return {
        getUserInfo,
        uploadMemberInfo
    };

};
