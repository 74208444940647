"use client";
import React, {useEffect, useState} from "react";
import { requestGetGoodsCategory } from "@/lib/api/goods-categories";
import GoodsCategory from "./goods-category";
import Menu from "@/components/header/menu/menu";
import {GoodsCategoriesItemInterface} from "@/lib/type/goods-categories.type";
import {usePathname, useRouter} from "next/navigation";

export default function CategoryList({data}:{data:{list:GoodsCategoriesItemInterface[]}}) {
    const whiteList = ['/login','/register']
    const path = usePathname()
    const [show, setShow] = useState(true)
    useEffect(() => {
        if(whiteList.includes(path)){
            setShow(false)
        }
    }, [path]);
    return <div className="w-full bg-white">
        {
            show &&
            <div className="container bg-white">
                <ul className="flex s-flex text-lg select-none space-x-10 font-bold">
                    <li>
                        {
                            data && <GoodsCategory goodsCategories={data.list}/>
                        }
                    </li>
                    <Menu/>
                </ul>
            </div>
        }

    </div>;
}