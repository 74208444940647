import(/* webpackMode: "eager" */ "__barrel_optimize__?names=App,ConfigProvider!=!/vercel/path0/node_modules/antd/es/index.js");
import(/* webpackMode: "eager" */ "__barrel_optimize__?names=Dropdown!=!/vercel/path0/node_modules/antd/es/index.js");
import(/* webpackMode: "eager" */ "__barrel_optimize__?names=message!=!/vercel/path0/node_modules/antd/es/index.js");
import(/* webpackMode: "eager" */ "/vercel/path0/node_modules/@ant-design/icons/es/components/AntdIcon.js");
import(/* webpackMode: "eager" */ "/vercel/path0/node_modules/@ant-design/nextjs-registry/es/AntdRegistry.js");
import(/* webpackMode: "eager" */ "/vercel/path0/node_modules/next/dist/client/legacy/image.js");
import(/* webpackMode: "eager" */ "/vercel/path0/node_modules/next/dist/client/link.js");
import(/* webpackMode: "eager" */ "/vercel/path0/src/app/globals.css");
import(/* webpackMode: "eager" */ "/vercel/path0/node_modules/remixicon/fonts/remixicon.css");
import(/* webpackMode: "eager" */ "/vercel/path0/public/alibaba/iconfont.css");
import(/* webpackMode: "eager" */ "/vercel/path0/public/antd.min.css");
import(/* webpackMode: "eager" */ "/vercel/path0/node_modules/next/font/google/target.css?{\"path\":\"src/app/layout.tsx\",\"import\":\"Inter\",\"arguments\":[{\"subsets\":[\"latin\"]}],\"variableName\":\"inter\"}");
import(/* webpackMode: "eager" */ "/vercel/path0/src/components/header/menu/category-list.tsx");
import(/* webpackMode: "eager" */ "/vercel/path0/src/components/header/nav-bar/nav-bar-right.tsx");
import(/* webpackMode: "eager" */ "/vercel/path0/src/components/header/promo/promo-left.tsx")