import request from "@/lib/util/request";
import {AccessToken, MemberInfoInterface, MemberInterface} from "@/lib/type/login-register.type";
import {FootprintItemInterface, UpdateMemberParams} from "@/lib/type/member.type";
import {UploadImageResponse} from "@/lib/type/upload-image.type";

// 会员个人中心 （可以用于校验token是否有效）
export const requestMemberInfo = (params?: AccessToken) => request<MemberInterface>({
    endpoint: "/api/tiny-shop/v1/member/member/index",
    method: "GET",
    params
});

// 更新会员信息
export const requestUpdateMemberInfo = (params: {
    id: string | number;
} & Partial<AccessToken>, body: UpdateMemberParams) => request<MemberInfoInterface>({
    endpoint: "/api/tiny-shop/v1/member/member/update",
    method: "PUT",
    body,
    params
});

// 上传图片接口
export const requestUploadImage = (data: FormData) => request<UploadImageResponse>({
    endpoint: "/api/v1/common/file/images",
    method: "POST",
    body: data,
    headers: {
        "Content-Type": "multipart/form-data"
    }
});

// 获取会员足迹
export const requestMemberFootprint = (params?: AccessToken) => request<FootprintItemInterface[]>({
    endpoint: "/api/tiny-shop/v1/member/footprint/index",
    method: "GET",
    params
});
