import {create} from "zustand";
import {persist} from "zustand/middleware";
import {GoodsCategoriesItemInterface} from "@/lib/type/goods-categories.type";

type GoodsState = {
    goodsCategories: GoodsCategoriesItemInterface[];
    setGoodsCategories: (categories: GoodsCategoriesItemInterface[]) => void;
}

export const useGoodsStore = create(
    persist<GoodsState>(
        (set, get) => ({
            goodsCategories: [],
            setGoodsCategories: (categories: GoodsCategoriesItemInterface[]) => set({goodsCategories: categories})
        }),
        {
            name: "__goodsStore",
            // 需要持久化存储的key
            partialize: (state: GoodsState): any => ({
                goodsCategories: state.goodsCategories
            })
        }
    )
);

